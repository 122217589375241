<template>
    <div class="home-container">
      <h1>What are you doing here?</h1>
      <p>Shoo!</p>
    </div>
  </template>
  
  <script>
  import { useRouter } from 'vue-router'
  
  export default {
    name: 'HomePage',
    setup() {
      const router = useRouter()
  
      const goToLogin = () => {
        router.push('/login')
      }
  
      return {
        goToLogin
      }
    }
  }
  //<Button label="Go to Login" @click="goToLogin" />
  </script>
  
  <style scoped>
  .home-container {
    text-align: center;
    padding: 2rem;
    background-color: #1e1e1e;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    color: #ffffff;
  }
  
  p {
    color: #cccccc;
  }
  </style>