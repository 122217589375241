import { createRouter, createWebHistory } from 'vue-router';
// import LoginPage from '../components/LoginPage.vue';
import HomePage from '../components/HomePage.vue';
// import ScrapedPage from '../components/ScrapedPage.vue';

const routes = [
  {
    // path: '/login',
    // name: 'Login',
    // component: LoginPage
  // },
  // {
    path: '/',
    name: 'Home',
    component: HomePage,
  // },
  // {
  //   path: '/scraped',
  //   name: 'Scraped',
  //   component: ScrapedPage
  },
  // Catch-all route that redirects any unknown route back to the base URL
  {
    path: '/:pathMatch(.*)*',  // Catch-all route
    redirect: '/',
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
