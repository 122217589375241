import { createApp } from 'vue'
import App from './App.vue'
import PrimeVue from 'primevue/config'
import router from './router'
import 'primeicons/primeicons.css'

// PrimeVue components
import InputText from 'primevue/inputtext'
import Password from 'primevue/password'
import Button from 'primevue/button'
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';   // optional
import Row from 'primevue/row';
import MultiSelect from 'primevue/multiselect';
import DatePicker from 'primevue/datepicker';
import InputNumber from 'primevue/inputnumber';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import Select from 'primevue/select';
import Tag from 'primevue/tag';

const app = createApp(App)

app.use(PrimeVue, { ripple: true, inputStyle: 'filled', theme: 'dark' })
app.use(router)

// Register PrimeVue components
app.component('InputText', InputText)
app.component('InputNumber', InputNumber)
app.component('CustomPassword', Password)
app.component('CustomButton', Button)   
app.component('CustomDataTable', DataTable)
app.component('CustomColumn', Column)
app.component('CustomColumnGroup', ColumnGroup)
app.component('CustomRow', Row)
app.component('CustomMultiSelect', MultiSelect)
app.component('CustomDatePicker', DatePicker)
app.component('CustomIconField', IconField)
app.component('CustomInputIcon', InputIcon)
app.component('CustomSelect', Select)
app.component('CustomTag', Tag)

app.mount('#app')